<template>
	<router-view />
</template>

<style lang="scss">
	html,
	body {
		height: 100%;
		margin: 0;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	#app {
		font-family: Roboto, Helvetica, Arial, sans-serif;
		height: 100%;
		padding: 0 0.75rem;
	}

	.container {
		padding: 1rem 0;
		margin: 0 auto;
		max-width: 1000px;
		width: 100%;
	}

	nav {
		a {
			font-weight: bold;
			color: #000;
			text-decoration: none;

			&.router-link-exact-active,
			&:hover {
				color: #1f69ff;
			}

			&:hover {
				text-decoration: underline;
			}
		}
	}
</style>
